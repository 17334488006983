/* @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap"); */


@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Pacifico&family=Playpen+Sans:wght@100;200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Serif:ital,opsz,wght@0,8..144,100;0,8..144,200;0,8..144,300;0,8..144,400;0,8..144,500;0,8..144,600;0,8..144,700;0,8..144,800;0,8..144,900;1,8..144,100;1,8..144,200;1,8..144,300;1,8..144,400;1,8..144,500;1,8..144,600;1,8..144,800;1,8..144,900&family=Stylish&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #14171a;
  color: white;
  font-family: poppins;
}


.web-app{
  max-width: 2000px;
  margin: auto;
  background: #111111;
  box-shadow: rgba(134, 134, 134, 0.12) 0px 2px 4px 0px, rgba(126, 126, 126, 0.32) 0px 2px 16px 0px;
  
}

.error-page{
  text-align: center;
  display: grid;
  gap: 10px;
}

button {
  cursor: pointer;
}


.home-pg-padding{
  padding: 0 20px;
}


/* ------------- header start ---------------- */

.header {
  padding: 20px 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: center;
  background-color: black;
  background-size: 100% 100%;
}
.header-col-2 {
  width: 90%;
}
.header-col-2 img {
}

.head-img-1 {
  width: 90%;
  position: relative;
}
.head-img-2 {
  position: absolute;
  top: 80px;
  left: 52%;
  width: 520px;
  height: 520px;
}
.head-img-4 {
  border-radius: 50px 0 50px 50px;
  width: 105%;
}

.header-col-1 {
  text-align: center;
  font-family: poppins;
  padding: 30px;
}

.header-c-heading-1 {
  font-size: 2rem;
  font-weight: 600;
}
.header-c-heading-2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(156, 156, 156);
  padding-bottom: 30px;
}

.header-c-inc {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.header-c-inc-col {
  font-size: 1rem;
}
.header-c-inc-col span {
  font-size: 1.8rem;
  font-weight: 600;
  color: red;
}

.header-c-form > input {
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.header-c-form > button {
  font-size: 1.2rem;
  padding: 10px 40px;
  font-weight: 600;
  border-radius: 10px;
  background-color: red;
  color: white;
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .header {
    grid-template-columns: 1fr;
    padding: 20px;
    background-image: none;
  }
  .header-col-2 {
    display: none;
  }

  .header-col-1 {
    padding: 0;
  }
  .header-c-heading-1 {
    font-size: 1.5rem;
  }

  .header-c-heading-2 {
    font-size: 1rem;
    padding-bottom: 20px;
  }

  .header-c-form > input {
    font-size: 1rem;
    padding: 8px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .header-c-form > button {
    
    font-size: 1.1rem;
    padding: 8px 40px;
    font-weight: 600;
    border-radius: 25px;
    background-color: red;
    color: white;
    margin-bottom: 20px;
  }

  .header-c-inc-col {
    font-size: 0.8rem;
  }
  .header-c-inc-col span {
    font-size: 1.5rem;
  }
  .header-c-inc {
    gap: 30px;
  }
}
/* ------------- header end ---------------- */

/* ---------- ourServiceComponent start ---------- */

.our-service {
  font-family: poppins;
  text-align: center;
}
.our-service-c-heding-1 {
  font-size: 3rem;
  font-weight: 600;
  color: red;
  line-height: 50px;
}

.our-service-content {
  margin: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.our-s-col-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 30px 0;
  text-align: end;
}

.our-s-col-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 30px 0;
  text-align: start;
}

.our-s-img {
  padding: 0 20px;
}

.ourService-img {
  filter: drop-shadow(0 0px 0.3rem rgba(255, 0, 0, 0.418));


}

.our-s-c-heading {
  font-size: 2rem;
  font-weight: 500;
  color: red;
  margin-bottom: 20px;
}

.our-s-c-points {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 1.2rem;
}

.our-s-c-points > div:nth-child(1) {
  margin-right: -10px;
}

.our-s-c-points > div:nth-child(5) {
  margin-right: -30px;
}

.our-s-c-points > div:nth-child(2),
.our-s-c-points > div:nth-child(4) {
  margin-right: 30px;
}

.our-s-c-points > div:nth-child(3) {
  margin-right: 50px;
}

.our-s-l-points {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 1.2rem;
}

.our-s-l-points span {
  color: red;
}

.our-s-l-points > div:nth-child(2),
.our-s-l-points > div:nth-child(5) {
  margin-left: 30px;
}

.our-s-l-points > div:nth-child(3) {
  margin-left: 60px;
}
.our-s-l-points > div:nth-child(4) {
  margin-left: 60px;
}

.our-service-c-heding-2 {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: rgb(156, 156, 156);
}

.our-s-img-1 {
  position: relative;
  top: 20px;
  margin-left: -30px;
}
.our-s-img-2 {
  position: absolute;
  right: 33%;
  width: 35%;
}

.our-service img {
  /* padding: ; */
  width: 100%;
  margin: auto;
  padding: 0 0 0 20px;
}

@media only screen and (max-width: 500px) {
  .our-service-content {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0px 0 20px;
  }

  .our-service-c-heding-1 {
    font-size: 1.8rem;
  }
  .our-service-c-heding-2 {
    font-size: 1rem;
  }

  .our-s-col-2,
  .our-s-col-1 {
    padding: 0 20px;
  }

  .our-s-col-1 {
    text-align: start;
  }

  .our-s-c-heading {
    font-size: 1.5rem;
    margin-bottom: 0px;
  }
  .our-s-c-points > div,
  .our-s-l-points > div {
    margin: 0px 10px !important;
    font-size: 0.8rem;
  }
  .our-s-l-points > div::before,
  .our-s-c-points > div::before {
    content: "\25c8";
    color: red;
    font-size: 1.2rem;
    margin-right: 10px;
  }

  .our-s-col-1 {
    padding: 10px;
  }

  .our-s-img {
    order: -1;
    width: 100%;
  }
  .our-s-img img {
    width: 90%;
  }

  .our-s-img-1 {
    position: relative;
    margin-left: 9px;
  }
  .our-s-img-2 {
    position: absolute;
    right: 3%;
  }

  .our-service img {
    width: 80%;
    margin: auto;
    padding: 0px 0;
  }
}

/* ---------- ourServiceComponent end ---------- */

/* ------------- Offer start ---------------- */

.offer-main {
  margin: 20px 0;
}
.offer-heading {
  text-align: center;
  color: red;
  font-size: 2.5rem;
  font-weight: 700;
}
.offer-slider {
  margin: 20px 50px;
}

.offer-img {
  padding: 10px;
}
.offer-img img {
  width: 100%;
  border-radius: 20px;
}

.slick-dots li button:before {
  color: white;
  font-size: small;
}

.slick-dots li.slick-active button:before {
  color: red;
}

@media only screen and (max-width: 500px) {
  .offer-slider {
    margin: 28px;
  }
  .offer-img {
    padding: 5px;
  }
}

/* ------------- Offer end ---------------- */

/* ----------PopUp start ---------- */
.offer-popup {
  position: fixed;
  top: 0%;
  z-index: 2;
  width: 100%;
  margin: auto;
  height: 100vh;
  background-color: rgba(29, 29, 29, 0.962);
  animation: fadeInOpacity 1s;
  box-shadow: rgba(251, 251, 252, 0.499) 0px 7px 29px 0px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.offer-section {
  background-color: #e82434;
  background-image: url(../src/media/images/bg-2.jpg);
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 35%;
  height: 60vh;
  
  box-shadow: rgba(251, 251, 252, 0.373) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  padding-bottom: 30px;
  color: white;
}
.offer-popup-main {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.offer-content {
}
.offer-text-1 {
  font-size: xx-large;
}
.offer-text-2 {
  line-height: 50px;
  font-size: 4rem;
  font-weight: bold;
}
.offer-text-3 {
  padding: 5px 0 10px;
  font-size: 1.3rem;
}
.offer-text-4 {
  font-size: 1.3rem;
}
.offer-closeBtn {
  padding: 20px 20px 0;
  width: 100%;
  text-align: end;
  background: none;
  border: none;
}
.offer-icon {
  color: white;
  font-size: x-large;
  font-weight: bold;
}

.offer-claim div{
  
}

.offer-claim {
  color: #ffffff;
  font-weight: bold;
  font-size: x-large;
  font-size: 1.4rem;
  padding: 8px 35px;
  background-color: rgb(255, 0, 0);
  text-decoration: none;
  border-radius: 50px;
  border: solid 2px;
}

/* offer-2 */

.offer-content-2 {
  padding: 10px 30px;
  display: grid;
  grid-template-columns: 35% 65%;
  justify-content: space-evenly;
  align-items: center;
}
.offer-txt {
  text-align: end;
}

.offer-txt-1 {
  font-size: xxx-large;
  font-weight: bold;
}
.offer-txt-2 {
  font-size: 1.2rem;
  padding-bottom: 15px;
}
.offer-txt-0 {
  font-size: 1.5rem;
}


.off-sli {
  width: 100%;
}
.offer-content input {
  font-size: 1.1rem;
  padding: 7px 15px;
  border-radius: 10px;
  border: none;
  margin-bottom: 10px;
  background-color: rgb(228, 228, 228);
  color: red;
}
@media only screen and (max-width: 500px) {
  .offer-section {
    width: 90%;
    height: 400px;
  }
  .offer-content-2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .offer-txt {
    text-align: center;
  }
  .offer-img {
    order: -1;
  }
  .offer-popup-main {
    padding: 20px;
  }
  .offer-text-1 {
    font-size: 1.5rem;
  }
  .offer-text-2 {
    font-size: 2.8rem;
  }
  .offer-text-3 {
    font-size: 1.2rem;
  }
  .offer-content input {
    font-size: 1.2rem;
    /* padding: 10px ;  */
  }
  .offer-claim {
    font-size: 1.2rem;
  }
}

/* ----------PopUp end ---------- */

/* ----------------- location -------------- */
.closeLoc {
  position: fixed;
  font-size: 2rem;
  top: 10px;
  right: 10px;
}
.location-col {
  padding: 80px;
  background-color: #9f1616;
  position: fixed;
  top: 0px;
  right: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.location-col-h1 {
  font-size: 2.1rem;
  font-weight: 600;
  text-align: center;
}
.select-btn {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  text-align: center;
}
.select-btn div {
  display: grid;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.select-btn div img {
  border-radius: 50%;
  width: 100px;
}

.select-locatin {
  margin-top: 20px;
  text-align: center;
  font-size: 1.4rem;
  padding: 10px;
  font-weight: 600;

  border: solid;
}

@media only screen and (max-width: 500px) {
  .location-col {
    padding: 20px;
    height: 100%;
    width: 100%;
    z-index: 3;
  }
  .select-btn div img {
    width: 60px;
  }

  .select-btn {
    gap: 10px;
  }

  .select-btn div {
    font-size: 0.8rem;
  }

  .select-locatin {
    margin-top: 20px;
    font-size: 1.1rem;
    padding: 4px;
  }
  .location-col-h1 {
    font-size: 1.8rem;
  }
}

/* ----------------- term and condition --------------- */

.termConditionMain {
  padding: 20px 100px;
}

.TC-heading-1 {
  font-size: 2.8rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
}
.TC-heading-1 span {
  color: red;
}

.TC-heading-2 {
  font-size: 1.3rem;
  font-weight: 600;
  color: red;
  margin-top: 30px;
}
.TC-text {
  margin-left: 20px;
  color: rgb(194, 194, 194);
}
.TC-points {
  margin-left: 40px;
}
.TC-points li {
  margin-bottom: 15px;
  color: rgb(194, 194, 194);
}
.TC-points li span {
  color: red;
  font-weight: 600;
}

.TC-textend {
  margin: 50px 0;
}

@media only screen and (max-width: 600px) {
  .termConditionMain {
    padding: 20px;
  }

  .TC-heading-1 {
    font-size: 1.6rem;
    margin: 0 20px;
  }

  .TC-heading-2 {
    font-size: 1.2rem;
    margin-top: 20px;
  }
  .TC-text {
    font-size: 0.9rem;
    margin-left: 10px;
  }

  .TC-points {
    margin-left: 20px;
    font-size: 0.9rem;
  }

  .TC-textend {
    margin: 30px 0;
  }
}

/* ---------------- how why goc ----------------- */


.our-work {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 85%;
  gap: 20px;
  margin: auto;
  max-width: 1300px;
  margin: 50px auto;
}

.our-work-txt-heading {
  font-size: xx-large;
  font-weight: 800;
  color: red;
  font-family: nunito;
}

.our-work-img {
  width: 100%;
  border: rgb(91, 91, 91) solid;
  padding: 15px;
  border-radius: 25px;
  display: flex;
}
.our-work-img img {
  width: 100%;
  border-radius: 15px;
  border: rgb(52, 52, 52) solid;
}
.our-work-txt-text {
  text-align: justify;
  padding: 0 10px;
}

.our-work-txt-points {
  padding: 10px 20px;
  display: grid;
  gap: 15px;
}

.our-work-txt-points > div::before {
  content: "\2756";
  font-size: 1.2rem;
  margin-right: 10px;
  color: red;
  text-decoration: none !important ;
}



/* --------------------------------------------------------------------------------- */



@media only screen and (max-width: 500px) {
  

  /* aa----------------------------------------- */
  
.our-work {
  width: 100%;
  grid-template-columns: 1fr;
  margin: 50px auto;
  padding: 0 20px;
  gap: 20px;
}

.our-work-img {
  padding: 10px;
  margin: 15px 0;
}

.our-work-txt-heading{
  padding-left: 5px;
  font-size: 1.3rem;
}
.our-work-txt-text{
  font-size: .77rem;
  color: rgb(206, 206, 206);
}
.our-work-txt-points {
  display: grid;
  gap: 5px;
  font-size: .8rem;
}

/* ---------------- */



  
}

/* ---------- about end ---------- */




.booked-page{
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.booked-page-content{
  background-color: black;
  text-align: center;
  padding: 50px;
}

.booked-page-headding-2{
  font-size: 3rem;
  font-weight: 700;
}


.booked-page-headding-2 span{
  color: red;
  font-size: 3rem;
}


.booked-page-headding-3{
  font-size: 2.5rem;
  color: red;
  font-weight: 600;
}

.booked-page-headding-4 a{
  color: red;
}
.booked-page-headding-4{
  font-size: 1.8rem;
}


@media only screen and (max-width: 500px) {
  
.booked-page{
  padding: 40px 20px;
}

.booked-page-content{
  padding: 10px;
}


.booked-page-headding-2{
  font-size: 1.8rem;
}


.booked-page-headding-2 span{
  color: red;
  font-size: 2rem;
  display: none;
}


.booked-page-headding-3{
  font-size: 1.3rem;
  color: red;
  font-weight: 600;
}

.booked-page-headding-4{
  font-size: 1.3rem;
}




}


