@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Pacifico&family=Playpen+Sans:wght@100;200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Serif:ital,opsz,wght@0,8..144,100;0,8..144,200;0,8..144,300;0,8..144,400;0,8..144,500;0,8..144,600;0,8..144,700;0,8..144,800;0,8..144,900;1,8..144,100;1,8..144,200;1,8..144,300;1,8..144,400;1,8..144,500;1,8..144,600;1,8..144,800;1,8..144,900&family=Stylish&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

/* logo start------------------------------ */
  .nav-logo {
    background-color: white;
    position: absolute;
    top: 0;
    padding: 20px;
    border-radius: 0 0 30px 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .nav-logo img{
    width: 150px;
    display: flex;
  }

  .footer-logo{
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    width: 250px;
    margin: auto;
    box-shadow: inset -4px -4px 6px 0 rgba(0, 0, 0, 0.452),inset 4px 4px 6px 0 #0006;
  }
  .footer-logo img{
    width: 100%;
  }

/* logo end------------------------------ */



body{
    background: #14171a;
    color: white;
}
.landing-page{
    font-family: poppins;
}
.landing-page-nav a {
    color: white;
    text-decoration: none;
  }
  .landing-page-nav-main{
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(255, 255, 255, 0.44) 0px 2px 10px 0px;

  }
  .landing-page-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    max-width: 1500px;
    margin: auto;
  }
  
  .landing-page-header-top {
    background-color: red;
    display: flex;
    justify-content: space-between;
    padding: 3px 110px;
  }
  .landing-page-header-top a {
    color: white;
    font-weight: 600;
  }
  .landing-page-header-top a:hover {
    font-weight: 700;
    text-decoration: none;
    color: black;
  }
  
  .landing-page-nav-logo {
    font-size: 2rem;
    font-weight: 600;
  }
  .landing-page-nav-logo i {
    font-size: 1.8rem;
  }
  .landing-page-nav-logo span {
    color: red;
  }
  
  .landing-page-nav-buttons {
    display: flex;
    gap: 20px;
  }
  
  .landing-page-nav-buttons a {
    background-color: red;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 1.1rem;
    box-shadow: inset -4px -4px 6px 0 #fff3,inset 4px 4px 6px 0 #0006;

  }
  .landing-page-nav-buttons a:hover {
    transition: 0.5s;
    background-color: rgb(255, 255, 255);
    color: rgb(255, 0, 0);
    font-weight: 600;
  }
  
  .landing-page-header-main{
    background: linear-gradient(45deg,#000,#161616 70%,#3b3b3b);
    padding: 40px 0;
  }
  .landing-page-header-content {
    max-width: 1300px;
    margin: 0px auto;
    display: grid;
    grid-template-columns: 4fr 3fr;
    gap: 50px;
  }
  
  .landing-page-header-content-col-1 {
    display: flex;
    flex-direction: column  ;
    justify-content: center;
    text-align: center;
    gap: 20px;
    margin: 50px;
  }
  
  .landing-page-header-content-col-1-img img {
    height: 200px;
    margin-left: -30px;
    margin-top: -20px;
  }
  
  .landing-page-header-content-col-1-heading {
    font-size: 2rem;
    font-weight: 600;
  }
  
  .landing-page-header-content-col-1-stars {
    display: flex;
    justify-content: center;
    gap: 5px;
    font-size: 2rem;
    color: red;
    padding: 15px 0 20px;
    
  }
  .landing-page-header-content-col-1-numbers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    color: rgb(165, 165, 165);
    font-size: 0.9rem;
  }
  
  .landing-page-header-content-col-1-numbers-n {
    font-size: 1.8rem;
    font-weight: 600;
    color: white;
  }
  

  .landing-page-header-content-col-2 {
    text-align: center;
    box-shadow: rgba(255, 255, 255, 0.44) 0px 2px 10px 0px;
    border-radius: 20px;
    background-color: black;
    background-color: white;
    color: black;
    padding: 20px 30px;
    max-width: 500px;
    box-shadow: 4px 4px 6px 0 #0000,-4px -4px 6px 0 #0000,inset -4px -4px 6px 0 #3f3f3fb2,inset 4px 4px 6px 2px rgba(64,64,64,.301);
  }
  
  .landing-page-header-content-col-2-img img {
    width: 250px;
    display: none;
  }
  .landing-page-header-content-col-2-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding: 20px 0;
  }
  
  .landing-page-header-content-col-2-form-heading img {
    display: none;
    display: block;
    width: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .landing-page-header-content-col-2-form-heading {
    font-size: 2rem;
    font-weight: 700;
    display: flex;
      align-items: center;
  }
  .landing-page-header-content-col-2-form-heading > span > span {
    color: red;
  }
  
  
.sb-c3-1-col {
  width: 100%;
  position: relative;
}
.select-brand-options {
  position: absolute;
  background-color: rgb(255, 255, 255);
  color: black;
  width: 100%;
  height: 250px;
  overflow: auto;
  padding: 0px 20px;
  z-index: 1;
  border: solid;
  border-radius: 10px;
}

.select-brand {
  background-color: rgb(0, 0, 0);
  background-color: transparent;
  border-radius: 12px;
  border: solid 2px;
  display: flex;
  align-items: center;
  padding: 9px 20px;
  justify-content: space-between;
  width: 100%;
  border: solid rgb(205, 205, 205) 2px;
font-size: 1rem;
}
  
  .landing-page-header-content-col-2-form form {
    display: grid;
    gap: 20px;
  }
  .landing-page-header-content-col-2-form form input {
    background-color: transparent;
    border: solid rgb(205, 205, 205) 2px;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1rem;
  }
  
  .landing-page-header-content-col-2-form form select{
    background-color: transparent;
    border: solid rgb(205, 205, 205) 2px;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1rem;
    -webkit-appearance: none !important;
  }
  
  .landing-page-header-content-col-2-form form option{
    color: black;
  }  
  .landing-page-header-content-col-2-form form button {
    color: white;
    background-color: red;
    font-size: 1.2rem;
    padding: 10px;
    border: solid white 1px;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
    box-shadow: inset -4px -4px 6px 0 rgba(0, 0, 0, 0.452),inset 4px 4px 6px 0 #0006;
    box-shadow: 4px 4px 6px 0 #ffffff80,-4px -4px 6px 0 #747d8880,inset -4px -4px 6px 0 #fff3,inset 4px 4px 6px 0 #0006;

  }
  
  .landing-page-header-content-col-2-form form button:hover {
    background-color: rgb(201, 12, 12);
    transition: 0.5s;
  }
  
  .landing-page-header-bottom {
    background-color: red;
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
    padding: 10px;
  }
  .landing-page-header-bottom a {
    color: white;
  }
  .landing-page-header-bottom a:hover {
    color: rgb(0, 0, 0);
    font-weight: 700;
    transition: 0.5s;
  }
  
  /* --------------------- price ------------------ */
  
  .landing-page-price-check-heading span {
    color: white;
    color: black;
  }
  .landing-page-price-check-heading {
    font-size: 2.5rem;
    font-family: stylish;

    font-weight: 600;
    color: red;
    text-align: center;
    padding-top: 10px;
    
  }
  
  .landing-page-price-check {
    border-radius: 30px;
    background-color: white;
    color: black;
    font-weight: 500;
    box-shadow: 4px 4px 6px 0 #c5c5c500,-4px -4px 6px 0 #0009,inset -4px -4px 6px 0 #b4b4b4,inset 4px 4px 6px 2px #40404080;
    position: relative;
  }
  
  .landing-page-price {
    max-width: 1300px;
    margin: 100px auto;
  }
  
  .landing-page-price-check-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 10px;
    padding: 40px 20px 40px 50px;
  }
  
  .landing-page-price-button {
    display: flex;
    justify-content: space-evenly;
  }
  .bengaluru-landing-page-price-button {
    width: 80%;
    margin: auto;

  }
  
  .landing-page-price-button-col {
    text-align: center;
    background-color: red;
    padding: 5px 20px 0px 20px;
    border-radius: 0 0 20px 20px;
    box-shadow: 4px 4px 6px 0 #000000b5,-4px -4px 6px 0 #0009,inset -4px -4px 6px 0 #3f3f3fb2,inset 4px 4px 6px 2px hsla(0, 0%, 46%, 0.5);
  }


  .bengaluru-landing-page-price-button-content{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    gap: 40px;
    padding: 5px 0 10px;
  }
  .bengaluru-landing-page-price-button-content>span>a{

    background-color: white;
    padding: 5px 20px;
    border-radius: 10px;
    color: red;

  }
  
  .landing-page-price-button-col-heading-1 {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .landing-page-price-button-col-heading-2 {
    font-size: 1.6rem;
    color: rgb(238, 238, 238);
    margin-bottom: 8px;
    font-weight: 600;
  }
  
  .landing-page-price-button-col-button {
    background-color: white;
    color: red;
    font-weight: 600;
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 15px;
    font-size: 1.2rem;
    box-shadow: inset -4px -4px 6px 0 #fff3,inset 4px 4px 6px 0 #0006;

  }
  
  .landing-page-price-button-col-button:hover {
    transition: 0.6s;
    background-color: red;
    color: white;
  }
  
  /* -------------- footer ---------- */
  
  .landing-page-footer {
    background-color: black;
  }
  .landing-page-footer-content {
    padding: 50px 0 ;
    max-width: 1300px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 10px;
  }
  
  .landing-page-footer-col-1 {
    text-align: center;
  }
  
  .landing-page-footer-col-1-logo span {
    color: red;
  }
  
  .landing-page-footer-col-1-logo i {
    font-size: 1.8rem;
  }
  .landing-page-footer-col-1-logo a {
    font-size: 2rem;
    font-weight: 600;
    color: white;
    text-decoration: none;
  }
  
  .landing-page-footer-col-1-tagline{
    font-size: 1.1rem;
    letter-spacing: 4px;
    word-spacing: 2px;
  }
  
  .landing-page-footer-col-2 {
    display: flex;
    justify-content: space-evenly;
  }
  
  .landing-page-footer-col-2-list-heading{
    font-size: 1.5rem;
    font-family: 'nunito';
    color: red;
    font-weight: 800;
  }
  
  .landing-page-footer-col-2-list-points {
    display: grid;
    gap: 5px;
    height: 100%;
  }
  
  .landing-page-footer-col-2-list-points>div>span{
    color: red;
  }
  .landing-page-footer-col-2-list-points{
    color: rgb(170, 170, 170);
  }
  .landing-page-footer-col-2-list-points a{
    color: rgb(170, 170, 170);
    text-decoration: none;
  }
  
  .landing-page-footer-col-2-list-points a:hover{
    color: white;
  }
  .footer-cc{
    text-align: center;
    padding: 10px ;
  }
  
  /* ---------- location ---------- */
  
  .landing-page-location{
    margin: 100px 0;
  }
  
  .landing-page-location-heading{
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    font-family: stylish;
  }
  .landing-page-location-heading span{
    color: red;
  }
  
  .landing-page-location-locations{
    display: flex;
    text-align: center;
    gap: 30px;
    justify-content: space-between;
    overflow: auto;
    padding: 10px 40px ;
    max-width: 1400px;
    margin: auto;
    margin-top: 20px;
  }
  
  .landing-page-location-locations-col{
    text-align: center;
    padding: 20px;
    background-color: black;
    box-shadow: rgba(255, 255, 255, 0.2) 2px 4px 10px;
  border-radius: 20px;
}

.landing-page-location-locations-col-icon img{
  border-radius: 50%;
  width: 100px;
}

.landing-page-location-locations-col-text{
  font-size: 1.1rem;
  font-weight: 500;
  width: 140px;
  }
  .landing-page-location-locations-col-text span{
    color: red;
    font-weight: 600;
  }

  /* -------------------------------------- */

  .keywords{
    width: 85%;
    margin: 50px auto;
  }
  .keywords-heading{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .keywords-heading>div{
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    font-family: stylish;
  }
  .keywords-heading>span{
    text-decoration: underline;
    cursor: pointer;
    width: 130px;
  }
  .keywords-heading span{
    color: red;
  }
  .keywords-content{
    color: rgb(170, 170, 170);
    margin-top: 20px;
  }
  .keywords-content span{
    color: red;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 800px) {

    
/* logo start------------------------------ */
.nav-logo {
  background-color: white;
  position: absolute;
  top: 0;
  padding: 5px 10px ;
  border-radius: 0 0 20px 20px;
}

  .nav-logo img{
    width: 100px;
  }

  .footer-logo{
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    width: 50%;
    margin: auto;
  }

  .footer-logo img{
    width: 100%;
  }

/* logo end------------------------------ */

    
    
    .landing-page-header-top {
      padding: 5px 5px;
      font-size: 0.8rem;
    }
  
    .landing-page-nav {
      padding: 10px 15px;
    }
  
    .landing-page-nav-logo i {
      font-size: 1.3rem;
    }
    .landing-page-nav-logo {
      font-size: 1.3rem;
    }
  
    .landing-page-nav-buttons {
      gap: 10px;
    }
    .landing-page-nav-buttons a {
      background-color: red;
      padding: 5px 10px;
      border-radius: 10px;
      font-weight: 600;
      font-size: 0.9rem;
    }
  
    .landing-page-header-content {
      grid-template-columns: 1fr;
      padding: 20px;
    }
  
    .landing-page-header-content-col-1 {
      gap: 10px;
      margin: auto;
    }
  
    .landing-page-header-content-col-1-heading {
      font-size: 1.4rem;
      font-weight: 600;
    }
    .landing-page-header-content-col-1-stars {
      font-size: 1.7rem;
      padding: 10px;
    }
  
    .landing-page-header-content-col-1-numbers {
      gap: 15px;
      font-size: 0.8rem;
    }
  
    .landing-page-header-content-col-1-numbers-n {
      font-size: 1.3rem;
    }
  .landing-page-header-content-col-2{
    padding: 20px;
  }
    .landing-page-header-content-col-2-form {
      padding: 0px;
    }
  
    .landing-page-header-content-col-2-form-heading {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
    }
  
    .landing-page-header-content-col-1-img img {
      width: 70%;
      height: auto;
      margin-left: -30px;
    }
  
    .landing-page-header-content-col-2-form-heading img {
      display: block;
      width: 80px;
      margin-right: 10px;
    }
  
    .landing-page-header-content-col-2-form form {
      padding: 0px;
      gap: 15px;
    }
    
    .landing-page-header-content-col-2-form form input,
    .landing-page-header-content-col-2-form form select {
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
      color: black;
      padding: 7px 10px;
    border: solid rgb(205, 205, 205) 1px;
    }
    .select-brand{
      padding: 5px 10px;
    border: solid rgb(205, 205, 205) 1px;

    }
    .select-brand-options{
      height: 350px;
    }
    .landing-page-header-content-col-2-img {
      display: none;
    }
  
    .landing-page-header-bottom {
      font-size: 1.18rem;
      padding: 10px 5px;
    }
  
    /* --------- price --------- */
  
    .landing-page-price {
      margin: 50px 10px;
    }
  
    .landing-page-price-check-heading {
      font-size: 1.8rem;
      padding: 10px 40px;
    }
  
    .landing-page-price-check-list {
      padding: 10px 0 20px 20px;
      font-size: 0.8rem;
      gap: 15px;
      grid-template-columns: 1fr;
    }
  
    .landing-page-price-button {
      display: flex;
      overflow: auto;
      overflow-y: hidden;
      gap: 20px;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    .bengaluru-landing-page-price-button-content{
      flex-direction: column;
      gap: 10px;
      font-size: 1.3rem;
      padding: 0;
    }
    .bengaluru-landing-page-price-button-content>span>a{
      border: solid 3px;
      font-size: 1.2rem;
    }
    .landing-page-price-button-col{
      padding: 5px 5px 0;
    }
    .landing-page-price-button-col-heading-1 {
      width: 190px;
      font-size: 1.2rem;
    }
    .landing-page-price-button-col-heading-2 {
      font-size: 1.3rem;
      font-weight: 700;
    }
  
    .landing-page-price-button {
      margin: 0 30px;
    }
  
    .landing-page-price-button-col-button{
      padding: 5px 15px;
    }
  
    /* -------------- footer ---------- */
    .footer-cc{
        padding: 10px 0;
        font-size: .8rem;
      }
    .landing-page-footer-content{
      grid-template-columns: 1fr;
      padding: 30px 10px 50px;
      gap: 50px;
    }
  
    .landing-page-footer-col-2-list-heading{
      font-size: 1.3rem;
      margin-bottom: 10px;
    }
  
    .landing-page-footer-col-2{
      gap: 20px 0;
      flex-wrap: wrap;
      gap: 30px 10px;
      padding: 0 20px;
      display: grid;
      justify-content: center;
      text-align: center;
    }

    
  .landing-page-footer-col-2-list-points>div>span{
    display: none;
  }
    
    .landing-page-footer-col-2-list-points{
      /* height: 180px; */
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 1rem;
      margin-bottom: 10px;
    }
  
    .landing-page-footer-col-2-list-points-contact{
      /* height: 130px ; */
    }

    .landing-page-footer-col-2>.landing-page-footer-col-2-list:nth-child(1) .landing-page-footer-col-2-list-points{
      /* display: flex ; */
      flex-direction: row;
    }
    
      .landing-page-footer-col-2-list-points a span{
        display: none;
      }
      .landing-page-footer-col-2-list-points a i{
        height: 40px;
        width:  40px;
        border: solid #b4b4b4 1px; 
        text-align: center;
        align-content: center;
        border-radius: 50%;
        font-size: 1.4rem;
      }
      

  
  .landing-page-footer-col-1-logo a {
    font-size: 1.6rem;
  }
    
  
  .landing-page-footer-col-1-logo i {
    font-size: 1.5rem;
  }
  
  
  .landing-page-footer-col-1-tagline{
    font-size: 1rem;
    letter-spacing: 2px;
    word-spacing: 1px;
  }
  
  
  /* ---------- location ---------- */
  
  .landing-page-location{
    margin: 50px 0;
  }
  .landing-page-location-heading{
    font-size: 1.8rem;
    margin: 0 20px;
  }
  .landing-page-location-locations{
    overflow: auto;
    gap: 20px;
    justify-content: start;
    padding: 10px 20px;
    
  }
  
  
  .landing-page-location-locations-col{
    padding: 10px;
    min-width: none;
  }
  
  .landing-page-location-locations-col-icon img{
    width: 80px;
  }
  
  .landing-page-location-locations-col-text{
    font-size: .9rem;
    width: 115px;
  }
    

  
  /* -------------------------------------- */

  .keywords{
    width: 100%;
    margin: 60px auto ;
    padding: 0 20px;
  }
  .keywords-heading{
    display: flex;
    flex-direction: column;
  }
  .keywords-heading>div{
    font-size: 1.8rem;
  }

  .keywords-heading>span{
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
  
  .keywords-heading span{
    color: red;
  }
  .keywords-content{
    color: rgb(170, 170, 170);
    margin-top: 10px;
    font-size: .9rem;
  }
  .keywords-content span{
    color: red;
    cursor: pointer;
  }
  
  
  }
  
  @media only screen and (max-width: 400px) {

    .landing-page-nav-logo i {
      font-size: 1rem;
    }
    .landing-page-nav-logo {
      font-size: 1.1rem;
    }
  
    .landing-page-nav-buttons {
      gap: 5px;
    }
    .landing-page-nav-buttons a {
      padding: 3px 7px;
      font-size: 0.8rem;
      border-radius: 5px;
    }

    
    .landing-page-header-bottom {
      font-size: 1.08rem;
      padding: 10px 5px;
    }
    
    
  }
  