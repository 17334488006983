
/* HTML: <div class="loader"></div> */
.loader {
  width: 88px;
  height: 12px;
  --_g: no-repeat radial-gradient(farthest-side,#000 94%,#0000);
  background:
    var(--_g) 25% 0,
    var(--_g) 75% 0;
  background-size: 12px 12px;
  position: relative;
  animation: l24-0 1s linear infinite;
}
.loader:before {
  content: "";
  position: absolute;
  height: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #000;
  inset: 0;
  margin: auto;
  animation: l24-1 1s cubic-bezier(0.5,300,0.5,-300) infinite;
}
@keyframes l24-0 {
  0%,24%  {background-position: 25% 0,75% 0}
  40%     {background-position: 25% 0,85% 0}
  50%,72% {background-position: 25% 0,75% 0}
  90%     {background-position: 15% 0,75% 0}
  100%    {background-position: 25% 0,75% 0}
}
@keyframes l24-1 {
  100% {transform:translate(0.1px)}
}


/* ------------- Animations start ---------------- */

.zoomIn {
    /* visibility: visible; */
    /* ------------- Select Bike end ---------------- */
    /* animation-delay: 0.1s; */
    animation-duration: 2s;
    animation-name: zoomIn;
  }
  
  @keyframes zoomIn {
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    50% {
      opacity: 1;
    }
  }
  
  .shake {
    animation: shake 1.3s ease infinite;
  
    /* animation: 3s anim-popoutin ease infinite; */
  }
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    5% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    10% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    15% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    20% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    25% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    30% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    35% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    40% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    45% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    50% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
    55% {
      transform: translate(-3px, 1px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    80% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    90% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    100% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
  }

  

  
  .fadeInOpacity {
    animation: fadeInOpacity 3s ease infinite;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: .2;
    }
    100% {
      opacity: 1;
    }
  }
  

  
  .imgrot {
    animation: imgRotate 50s linear infinite;
  }
  
  @keyframes imgRotate {
    100% {
      transform: rotate(360deg);
    }
  }

  
  .pop-outin {
    animation: 3s anim-popoutin ease infinite;
  }
  
  @keyframes anim-popoutin {
    0% {
      color: white;
      transform: scale(1);
      opacity: 1;
      text-shadow: 0 0 0 rgba(75, 75, 75, 0);
    }
    50% {
      color: rgb(255, 255, 255);
      transform: scale(1.4);
      opacity: 1;
      text-shadow: 7px 7px 2px rgba(29, 29, 29, 0.688);
    }
  
    100% {
      /* animate nothing to add pause at the end of animation */
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(78, 78, 78, 0);
    }
  }
  
  
  .flipX {
    animation: 6s anim-flipX ease infinite;
  }
  @keyframes anim-flipX {
    0% {
      opacity: 0;
      transform: rotateX(90def);
    }
    25% {
      opacity: 1;
      transform: rotateX(720deg);
      color: red;
    }
    100% {
      /* animate nothing to pause animation at the end */
      opacity: 1;
      transform: rotateX(720deg);
    }
  }
  
  slideIn{
    animation: 3s slideIn;
  }
  
  @keyframes slideIn {
    0% {
      margin-bottom: 20vh;
    }
    
    100% {
      margin-top: 90vh;
    }
  }
  


  @keyframes fadeinout {
    0%,100% { opacity: .3; }
    50% { opacity: 1; }
  }
  
  
  
  /* ------------- Animations end ---------------- */