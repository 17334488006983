/* ----------------- offer button ------------------- */
.offerButton {
  background-color: red;
  color: white;
  padding: 18px;
  border: 2px solid white;
  font-size: large;
  font-weight: bolder;
  border-radius: 50%;
  margin-top: 20px;
  /* position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1; */
  /* animation: zoomIn 1s infinite; */
}

.offerButton i {
  font-size: xx-large;
}
@media only screen and (max-width: 500px) {
  .offerButton {
    padding: 9px;
    /* margin: 15px 10px 0 0; */
    /* font-size: large; */
    /* bottom: 30px; */
    /* right: 12px; */
  }
  .offerButton i {
    font-size: 1.4rem;
  }
}

/* ----------------- call button ------------------- */

/* .button-call-btn {
    background-color: red;
    color: white;
    padding: 15px;
    border: 2px solid white;
    font-size: large;
    font-weight: bolder;
    border-radius: 50%;
    position: fixed;
    bottom: 15%;
    left: 20px;
    z-index: 1;
    animation: shake 1s infinite;
  } */

/* ------------ side ------------- */

.side-fix-buttons {
  position: fixed;
  top: 40%;
  animation: fadeinout 4s infinite linear forwards;
  /* left: 10%; */
}
.side-fix-heading {
  background: none;
  transform: rotate(270deg);
  /* margin: -30px ; */
  margin: 0 0 40px -40px;
  font-size: 1rem;
  letter-spacing: 3px;
  color: rgb(255, 255, 255);
}

.side-fix-b-icon a {
  /* color: rgb(167, 167, 167); */
  padding: 10px;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
}
.side-fix-b-icon a i {
  margin-top: 10px;
}

@media only screen and (max-width: 500px) {
  .side-fix-buttons {
    top: 45%;
  }

  .side-fix-heading {
    font-size: 0.7rem;
    letter-spacing: 3px;
    margin: 0 0 20px -30px;
  }

  .side-fix-b-icon a {
    padding: 5px;
    font-size: 1rem;
  }
  .side-fix-b-icon a i {
    margin-top: 10px;
  }
}

/* --------call---------- */

.contact-fix-btn {
  position: fixed;
  top: 45%;
  right: 10px;
}

.call-fix-buttons,
.whatsapp-fix-buttons {
  background-color: red;
  /* border: none; */
  display: inline-block;
  /* border: solid 2px; */
  border-radius: 50%;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset -4px -4px 6px 0 #fff3,inset 4px 4px 6px 0 #0006;

}
.call-fix-buttons a,
.whatsapp-fix-buttons a {
  background-color: transparent;
  border: none;
  /* padding: 10px; */
  /* font-size: xx-large; */
  /* font-size: 2.2rem; */
  font-weight: bolder;
  color: white;
}

.call-fix-buttons {
  animation: shake 1.3s ease 0.6s infinite;
  font-size: 2rem;
}
.whatsapp-fix-buttons {
  font-size: 2.5rem;
  margin-top: 15px;
  background-color: rgb(10, 177, 10);
  animation: shake 1.3s ease infinite 1s;
}

@media only screen and (max-width: 500px) {
  .whatsapp-fix-buttons {
    margin-top: 15px;
  }
  .call-fix-buttons a{
    font-size: 1.4rem;

  }
  .whatsapp-fix-buttons a {
    font-size: 1.9rem;
  }
  .call-fix-buttons,
  .whatsapp-fix-buttons {
    height: 50px;
    width:  50px;
  }
}

.top-btn {
  position: fixed;
  right: 20px;
  bottom: 40px;
}

.top-btn button {
  color: white;
  /* padding: 10px; */
  width: 60px;
  height: 60px;
  border: solid 2px;
  font-size: 1.8rem;
  border-radius: 50%;
  background-color: red;
}

@media only screen and (max-width: 500px) {
  .top-btn {
    position: fixed;
    right: 10px;
    bottom: 10px;
  }

  .top-btn button {
    color: white;
    width: 40px;
    height: 40px;
    border: solid 2px;
    font-size: 1.2rem;
    border-radius: 50%;
    background-color: red;
  }
}

.help-fix-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

.help-fix-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1rem;
}
.help-fix-btn span {
  padding: 5px 10px;
  background-color: rgb(73, 73, 73);
}

.help-fix-btn i {
  border: solid 3px white;
  color: red;
  /* color: white; */
  border-radius: 50%;
  font-size: 3rem;
  background-color: white;
  font-weight: bold;
  /* background-color: red; */
}

.help-fix-form {
  display: grid;
  background-color: black;
  gap: 15px;
  box-shadow: rgba(255, 255, 255, 0.2) 2px 4px 10px;
  padding: 20px;
}
.help-fix-form input,
.help-fix-form textarea {
  padding: 5px;
  font-size: 1rem;
}

.help-fix-form-btn {
  color: white;
  background: red;
  font-size: 1.2rem;
  font-weight: 600;
  /* font-size: 1rem; */
  border: rgb(226, 154, 154) 2px solid;
  padding: 5px;
}

.help-fix-close-btn {
  /* border-radius: 50%; */
  color: red;
  color: white;
  background-color: transparent;
  font-size: 3.4rem;
  border: none;
  
}

.help-fix-frm {
  position: fixed;
  bottom: 20px;
  left: 20px;
}

@media only screen and (max-width: 500px) {
  .help-fix-btn {
    font-size: .8rem;
  }
  
.help-fix-btn span {
  padding: 5px 8px;
}

.help-fix-btn i {
  font-size: 2.4rem;
}

.help-fix-close-btn{
  font-size: 2.5rem;
}

.help-fix-form input,
.help-fix-form textarea {
  padding: 4px;
  font-size: .8rem;
}

.help-fix-form-btn {
  font-size: 1rem;
}


  
}
